<script lang="ts" setup>
const current = new Date()
const date = `${current.getFullYear()}`
const localeRoute = useLocaleRoute()
const { t } = useI18n()
const {
  public: { appStoreLink, googlePlayLink },
} = useRuntimeConfig()
</script>

<template>
  <footer>
    <div class="flex flex-col items-center">
      <div
        class="pb-8 px-5 pt-16 md:w-full md:max-w-237.5 lg:px-0 xl:max-w-316.5 xl:py-27 xl:px-5 xl:w-full min-[1300px]:!px-0"
      >
        <div
          class="flex flex-col justify-center gap-5 md:gap-16 xl:gap-0 xl:flex-row-reverse xl:justify-between"
        >
          <div
            class="md:flex md:flex-row md:gap-10 md:justify-between xl:flex-col xl:mx-0"
          >
            <div class="flex max-w-97 w-full mx-auto md:mx-0">
              <div
                class="rounded-[1.25rem] flex flex-row justify-between items-end px-4 pt-4 gap-4.5 min-[26.75rem]:gap-8.5 w-full"
                style="
                  background: linear-gradient(
                    180.11deg,
                    #100d0d 0.09%,
                    #7540ee 175.59%
                  );
                "
              >
                <div class="flex flex-col gap-4 pb-7.5">
                  <p class="text-[#ABABAB] text-[1.25rem]">Download Belong</p>
                  <div>
                    <div
                      class="flex flex-row gap-3 min-[26.25rem]:gap-9.5 md:gap-6.5"
                    >
                      <NuxtLink
                        class="flex flex-row items-center gap-2 text-[#ABABAB]"
                        :to="appStoreLink"
                        target="_blank"
                        aria-label="Belong in AppStore"
                      >
                        <i class="i-belong:appstore w-5 h-5" />
                        <p
                          class="uppercase font-500 text-sm tracking-wide text-xs min-[26.5625rem]:whitespace-pre"
                        >
                          App Store
                        </p>
                      </NuxtLink>
                      <NuxtLink
                        class="flex flex-row items-center gap-2 text-[#ABABAB]"
                        :to="googlePlayLink"
                        target="_blank"
                        aria-label="Belong in Google Play"
                      >
                        <i class="i-belong:google-play w-5 h-5" />
                        <p
                          class="uppercase font-500 text-sm tracking-wide text-xs min-[26.5625rem]:whitespace-pre"
                        >
                          Google Play
                        </p>
                      </NuxtLink>
                    </div>
                  </div>
                </div>

                <NuxtImg
                  loading="lazy"
                  format="webp"
                  src="/images/home/phone-footer.png"
                  class="max-w-16.5 w-16.5 h-24"
                  alt=""
                />
              </div>
            </div>

            <div
              class="hidden md:flex md:flex-row md:justify-end md:gap-6 md:items-center"
            >
              <NuxtLink
                class="flex"
                to="https://moralis.io/"
                target="_blank"
                aria-label="Moralis"
              >
                <i class="i-belong:moralis w-32 h-5.5" />
              </NuxtLink>

              <NuxtLink
                to="https://audits.hacken.io/belong-net/"
                target="_blank"
                class="opacity-40 flex"
                aria-label="Hacken"
              >
                <i class="i-belong:proofed w-30.5 h-10.5" />
              </NuxtLink>
              <NuxtLink
                to="https://magic.store/app/belong"
                target="_blank"
                class="flex"
                aria-label="Magic"
              >
                <NuxtImg
                  loading="lazy"
                  format="webp"
                  src="/images/home/validated-by-web.png"
                  class="opacity-40 w-9 h-9"
                  alt=""
                />
              </NuxtLink>
            </div>
          </div>
          <div
            class="links flex flex-col text-center justify-center gap-5 md:flex-row md:text-left md:justify-between xl:gap-21 xl:flex-row xl:text-left"
          >
            <div class="flex flex-col gap-5">
              <NuxtLink :to="localeRoute('/app')">
                {{ t('belong-app') }}
              </NuxtLink>
              <NuxtLink :to="localeRoute('/nft-tickets')">
                {{ t('belong-nft-ticketing') }}
              </NuxtLink>

              <NuxtLink :to="localeRoute('/creator-pass')">
                {{ t('belong-creator-pass') }}
              </NuxtLink>
            </div>
            <div class="flex flex-col gap-5">
              <NuxtLink :to="localeRoute('/founder-pass')">
                {{ t('belong-founder-pass') }}
              </NuxtLink>
              <NuxtLink :to="localeRoute('/paid-content-nft')">
                {{ t('gated-content') }}
              </NuxtLink>
              <NuxtLink :to="localeRoute('/mighty-networks-alternative')">
                {{ t('community-management') }}
              </NuxtLink>
            </div>
            <div class="flex flex-col gap-5">
              <NuxtLink :to="localeRoute('/blog')"> {{ t('blog') }} </NuxtLink>
              <NuxtLink :to="localeRoute('/hubs')">{{ t('hubs') }}</NuxtLink>
              <NuxtLink :to="localeRoute('/events')">{{
                t('events')
              }}</NuxtLink>
            </div>
            <div class="flex flex-col gap-5">
              <NuxtLink to="https://belong.betteruptime.com/" target="_blank">
                {{ t('service-status') }}
              </NuxtLink>
              <NuxtLink to="https://docs.belong.net/" target="_blank">{{
                t('api-docs')
              }}</NuxtLink>
              <NuxtLink :to="localeRoute('/how-to')">{{
                t('tutorials')
              }}</NuxtLink>
            </div>
          </div>
        </div>

        <div class="my-5 md:mt-9.5 lg:mt-14">
          <img
            alt=""
            src="/assets/images/token/line.svg"
            class="h-0.25 w-full"
          />
        </div>
        <div
          class="flex flex-col md:flex-row items-center gap-5 xl:flex-row justify-between"
        >
          <div class="flex flex-row gap-5 md:order-1 items-center">
            <NuxtLink
              to="https://discord.gg/QzwrUXXWCA"
              target="_blank"
              class="flex items-center justify-center opacity-40"
              aria-label="Discord"
            >
              <i class="i-belong:discord w-6 h-4.5 object-cover" />
            </NuxtLink>

            <NuxtLink
              to="https://twitter.com/belongnet"
              target="_blank"
              class="opacity-40 flex"
              aria-label="Twitter"
            >
              <i class="i-belong:twitter w-6 h-6 object-cover" />
            </NuxtLink>
          </div>

          <div
            class="flex flex-col items-center gap-6.5 md:order-2 md:flex-row"
          >
            <NuxtLink
              :to="localeRoute('/privacy')"
              class="capitalize text-[#A3A3A3] long-token"
              >{{ t('privacy-policy') }}</NuxtLink
            >
            <NuxtLink
              :to="localeRoute('/terms')"
              class="text-[#A3A3A3] long-token"
              >{{ t('eula') }}</NuxtLink
            >

            <UxLangSwitcher />
          </div>

          <div class="flex flex-row items-center gap-3.5 md:hidden">
            <NuxtLink
              class="flex"
              to="https://moralis.io/"
              target="_blank"
              aria-label="Moralis"
            >
              <i class="i-belong:moralis w-23 h-4" />
            </NuxtLink>

            <NuxtLink
              to="https://audits.hacken.io/belong-net/"
              target="_blank"
              class="opacity-40 flex"
              aria-label="Hacken"
            >
              <i class="i-belong:proofed w-19 h-6.5" />
            </NuxtLink>
            <NuxtLink
              to="https://magic.store/app/belong"
              target="_blank"
              aria-label="Magic"
            >
              <NuxtImg
                loading="lazy"
                format="webp"
                src="/images/home/validated-by-web.png"
                class="opacity-40 w-9 h-9"
                alt=""
              />
            </NuxtLink>
          </div>

          <div
            class="text-base md:text-xs lg:text-base text-center text-[#A3A3A3] md:order-0"
          >
            {{ t('copyright-belong') }} {{ date }}.
            {{ t('all-rights-reserved') }}
          </div>
        </div>
        <div
          v-if="$slots.title"
          class="text-sm text-[#A3A3A3] text-center mt-20"
        >
          {{ t('disclaimer') }}

          <slot name="title" />
        </div>
      </div>
    </div>
  </footer>
</template>

<style scoped>
.links {
  a {
    @apply text-[#A3A3A3] whitespace-pre;
  }
}
</style>
